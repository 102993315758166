module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rock Candy Leatherworks","short_name":"Rock Candy","start_url":"/","background_color":"#FDFDFD","theme_color":"#B2D033","display":"standalone","icon":"static/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c9e00ff2a09931c9cf3626f5662d5d0e"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-prettier-build/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6GE2XKVQQK"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-seagull/gatsby-browser.js'),
      options: {"plugins":[],"sanityProjectId":"76bezskt","sanityDataset":"production","sanityGraphqlTag":"default","siteName":"Seagull Theme Base","homePath":"/"},
    }]
